import {
  Status,
  ACTION_SUCCESS,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  RegisterMemberParam, RegisterMemberResult,
  SearchMemberParam, SearchMemberResult
} from "../Member"
import {
  dateFormat
} from "../../typeconv"

export type MemberConfig = {
  host: string
}

export class Member {

  constructor(private config: MemberConfig) { }

  public async RegisterMember(p: RegisterMemberParam): Promise<RegisterMemberResult> {
    try {
      const url = `${this.config.host}/api/v1/members`
      
      const businessTypes = p.businessTypes || []
      const representatives = p.representatives ? p.representatives.map((representative) => {
        return {
          name: representative.name,
          designation: representative.designation,
          telephone: representative.telephone,
          email: representative.email,
          dob: representative.dob ? dateFormat(representative.dob, 'YYYY-MM-DD') : null,
        }
      }) : []
      const vehicles = p.vehicles ? p.vehicles.map((vehicle) => {
        return {
          type: vehicle.type,
          quantity: vehicle.quantity,
        }
      }) : []

      const params = {
        verification_token: p.verificationToken,
        name: p.name,
        uen: p.uen,
        telephone: p.telephone,
        email: p.email,
        website: p.website,
        logo: p.logo,
        location_street: p.locationStreet,
        mailing_street: p.mailingStreet,
      }
      const data = new FormData()
      for (const [key, value] of Object.entries(params)) {
        if (value) {
          data.set(key, value)
        }
      }

      for (let i = 0; i < businessTypes.length; i++) {
        const businessType = businessTypes[i]
        data.append(`business_types[${i}]`, businessType)
      }

      for (let i = 0; i < representatives.length; i++) {
        const representative = representatives[i]
        data.append(`representatives[${i}][name]`, representative.name)
        data.append(`representatives[${i}][designation]`, representative.designation)
        data.append(`representatives[${i}][telephone]`, representative.telephone)
        data.append(`representatives[${i}][email]`, representative.email)
        data.append(`representatives[${i}][dob]`, representative.dob)
      }

      for (let i = 0; i < vehicles.length; i++) {
        const vehicle = vehicles[i]
        data.append(`vehicles[${i}][type]`, vehicle.type)
        data.append(`vehicles[${i}][quantity]`, `${vehicle.quantity}`)
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
        redirect: "follow",
      })
      if (response.status !== 202) {
        const error = await Status.FromResponse(response)
        return {
          error,
        }
      }

      return {
        success: new Status("success register member", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchMember(p?: SearchMemberParam): Promise<SearchMemberResult> {
    try {
      const {
        firstChar,
        page,
        query,
        totalItems
      } = p || {}
      const url = `${this.config.host}/api/v1/members/search`

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          query: query,
          first_char: firstChar,
          page: page,
          total_items: totalItems,
        }),
        redirect: "follow",
      })

      if (response.status !== 200) {
        const error = await Status.FromResponse(response)
        return {
          error,
        }
      }

      const data = await response.json()

      return {
        success: new Status("success search member", ACTION_SUCCESS),
        data: {
          summary: data.data.summary,
          items: data.data.items.map((item: any) => {
            return {
              ...item,
              logoUrl: item.logo_url,
              locationStreet: item.location_street,
              joinedDate: item.joined_date,
              representatives: item.representatives || [],
              vehicles: item.vehicles || [],
            }
          }),
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}
