import * as React from "react"
import { To } from "react-router-dom"
import { Popover, Disclosure, Transition } from "@headlessui/react"
import { useFloating, useInteractions, useDismiss } from "@floating-ui/react"
import {
  HiMiniXMark, HiBars3,
  HiChevronDown, HiChevronUp
} from "react-icons/hi2"
import { useScrollPosition } from "../../hooks"
import { Base } from "./Base"
import { HeaderContext } from "./Context"
import { Container } from "../Container"
import { Link } from "../Router"

type HeaderProps = {
  children?: React.ReactNode
  logoImage?: string
  logoImageDark?: string
  logoUrl: To
  linkItems?: MenuItem[]
  sticky?: boolean
  fixed?: boolean
  dark?: boolean
}

type MenuItem = {
  to: To
  target?: string
  content: React.ReactNode
  hideCrawl?: boolean
  items?: MenuItem[]
}

export function Header(props: HeaderProps): React.JSX.Element {
  const {
    linkItems = [],
    sticky = false,
    fixed = false,
    dark: initialDark = false,
  } = props

  const { refs, context } = useFloating({
    placement: "bottom",
  })
  const dismiss = useDismiss(context)
  const { getReferenceProps } = useInteractions([
    dismiss,
  ])

  const [header] = React.useContext(HeaderContext)
  const [dark, setDark] = React.useState(initialDark)
  const { y: scrollY } = useScrollPosition()
  
  React.useEffect(() => {
    setDark(initialDark)

    const hasNoIntersector = !header.intersector && !header.intersector?.current
    if (hasNoIntersector) {
      return
    }

    if (scrollY === 0) {
      return
    }

    const factor = header.factor || 1
    const intersectorHeight = header.intersector?.current?.clientHeight
    const height = intersectorHeight * factor

    const alreadyIntersect = scrollY >= height
    if (alreadyIntersect) {
      setDark(false)
    } else {
      setDark(true)
    }
  }, [initialDark, header, scrollY])

  const logo = (dark ? (props.logoImageDark || props.logoImage) : props.logoImage)
  const bg = (dark ? "bg-sta-navy" : "bg-white")
  const text = (dark ? "text-white hover:text-sta-gray" : "text-black hover:text-sta-primary")

  return (
    <Base ref={refs.setReference} props={getReferenceProps()} sticky={sticky} fixed={fixed} appendClassNames="w-full h-fit font-inter">
      <div className="flex xl:hidden">
        <Container size="full" appendClassNames={bg}>
          <div className="flex items-center justify-between w-full h-[65px] z-10">
            <div className="flex flex-row justify-between gap-2">
              <Link to={props.logoUrl} className="no-underline text-sm">
                <img alt="logo" src={logo} className="w-16 h-12" />
              </Link>
            </div>

            <div className="flex flex-row justify-center items-center gap-2">
              <Popover>
                {({ open, close }) => (
                  <>
                    {open && <div className="fixed inset-0 bg-black opacity-30" />}
                    <Popover.Button className={"inline-flex items-center justify-center rounded-md "}>
                      <span className="sr-only">open menu</span>
                      {open ?
                        <HiMiniXMark className={"block w-8 h-8 text-black hover:text-sta-primary"} aria-hidden="true" />
                        :
                        <HiBars3 className={"block w-8 h-8 " + (text)} aria-hidden="true" />
                      }
                    </Popover.Button>
                    <Popover.Panel className="absolute top-0 left-0 w-full h-screen bg-white z-10 px-3 overflow-y-auto">
                      <div className="flex flex-col gap-10">
                        <div className="flex flex-row justify-between items-center gap-2 h-[65px]">
                          <Link to={props.logoUrl} className="no-underline text-sm">
                            <img alt="logo" src={props.logoImage} className="w-16 h-12" />
                          </Link>
                          <Popover.Button className={"inline-flex items-center justify-center rounded-md "}>
                            <span className="sr-only">open menu</span>
                            {open ?
                              <HiMiniXMark className={"block w-8 h-8 text-black hover:text-sta-primary"} aria-hidden="true" />
                              :
                              <HiBars3 className={"block w-8 h-8 " + (text)} aria-hidden="true" />
                            }
                          </Popover.Button>
                        </div>

                        {
                          linkItems.length > 0 &&
                          <ul className="flex flex-col gap-6 md:gap-8">
                            {
                              linkItems.map((linkMenu, i: number) => {
                                return (
                                  <li key={`link-item-mobile-${i}`}>
                                    {
                                      linkMenu.items && linkMenu.items.length > 0 ?
                                        <Disclosure>
                                          {({ open }) => (
                                            <div className="w-full flex flex-col gap-4">
                                              <Disclosure.Button className="flex flex-row justify-between items-center gap-2">
                                                <span className="text-xl font-medium text-black hover:sta-primary border-b-3 border-sta-primary">
                                                  {linkMenu.content}
                                                </span>
                                                <div className="hover:sta-primary border-b-3 border-sta-primary">
                                                  {open ?
                                                    <HiChevronUp className="block h-4 w-4 stroke-2 text-black" aria-hidden="true" />
                                                    :
                                                    <HiChevronDown className="block h-4 w-4 stroke-2 text-black" aria-hidden="true" />
                                                  }
                                                </div>
                                              </Disclosure.Button>
                                              <Disclosure.Panel as="ul" className="pl-6 flex flex-col gap-4">
                                                {
                                                  linkMenu.items &&
                                                  <div className="grid grid-cols-1 gap-4">
                                                    {
                                                      linkMenu.items.map((linkItem, j: number) => {
                                                        return (
                                                          <div key={`heading-item-mobile-${i}-${j}`} className="flex flex-col gap-4">
                                                            {
                                                              linkItem.to ?
                                                                <Popover.Button as={Link} to={linkItem.to}
                                                                  target={linkItem.target} hideCrawl={linkItem.hideCrawl}
                                                                  className="block no-underline text-black hover:text-sta-primary">
                                                                  {linkItem.content}
                                                                </Popover.Button>
                                                                :
                                                                <span className="block text-black">
                                                                  {linkItem.content}
                                                                </span>
                                                            }
                                                          </div>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                }
                                              </Disclosure.Panel>
                                            </div>
                                          )}
                                        </Disclosure>
                                        :
                                        <Link to={linkMenu.to} target={linkMenu.target} hideCrawl={linkMenu.hideCrawl}
                                          className="inline no-underline text-xl font-medium text-black hover:sta-primary border-b-3 border-sta-primary"
                                          onClick={() => { close() }}>
                                          {linkMenu.content}
                                        </Link>
                                    }
                                  </li>
                                )
                              })
                            }
                          </ul>
                        }
                      </div>
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            </div>
          </div>
        </Container>
      </div>

      <div className="hidden xl:flex">
        <Container size="full" appendClassNames={bg}>
          <div className="flex items-center justify-between gap-2 w-full h-[100px]">
            <div className="flex flex-row justify-between gap-2 divide-x-2 divide-black">
              <Link to={props.logoUrl} className="no-underline text-sm">
                <img alt="logo" src={logo} className="w-24 h-16" />
              </Link>
            </div>
            {
              linkItems.length > 0 &&
              <Popover.Group className="flex flex-row items-center justify-between gap-6 2xl:gap-12 p-2">
                {
                  linkItems.map((linkMenu, i: number) => {
                    return (
                      <Popover key={`link-item-desktop-${i}`} className={"text-xl " + text}>
                        {({ open, close }) => (
                          linkMenu.items && linkMenu.items.length > 0 ?
                            <SubmenuItem i={i} linkMenu={linkMenu} open={open} dark={dark} />
                            :
                            <Link to={linkMenu.to} target={linkMenu.target} hideCrawl={linkMenu.hideCrawl}
                              className={
                                "flex flex-row gap-1 border-b-3 border-transparent " +
                                (dark ? "hover:border-sta-secondary" : "hover:border-sta-primary")
                              }
                              onClick={() => { close() }}>
                              {linkMenu.content}
                            </Link>
                        )}
                      </Popover>
                    )
                  })
                }
              </Popover.Group>
            }
          </div>
        </Container>
      </div>
    </Base>
  )
}

type SubmenuItemProps = {
  i: number
  open: boolean
  dark?: boolean
  linkMenu: MenuItem
}

function SubmenuItem(props: SubmenuItemProps): React.JSX.Element {
  const { i, open, dark = false, linkMenu } = props

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
  })
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
  ])

  return (
    <>
      <Popover.Button ref={refs.setReference} {...getReferenceProps()}
        className={
          "flex flex-row justify-center items-center gap-2 text-center border-b-3 border-transparent " +
          (dark ? "hover:border-sta-secondary" : "hover:border-sta-primary")
        }>
        <span>{linkMenu.content}</span>
        {
          open ?
            <HiChevronUp className={"block h-4 w-4 " + (dark ? "text-sta-secondary" : "text-sta-primary")} aria-hidden="true" />
            :
            <HiChevronDown className={"block h-4 w-4 " + (dark ? "text-sta-secondary" : "text-sta-primary")} aria-hidden="true" />
        }
      </Popover.Button>

      <Transition as={React.Fragment}
        enter="ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0">
        <Popover.Panel className="w-full max-w-[300px] !top-2 bg-white z-10 p-6 rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
          ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()}>
          {
            linkMenu.items && linkMenu.items.length > 0 &&
            <div className="grid grid-cols-1 gap-2">
              {
                linkMenu.items.map((linkItem, j: number) => {
                  return (
                    <div key={`sublink-item-desktop-${i}-${j}`} className="flex flex-col gap-4">
                      {
                        linkItem.to ?
                          <div>
                            <Popover.Button as={Link} to={linkItem.to}
                              target={linkItem.target} hideCrawl={linkItem.hideCrawl}
                              className="no-underline text-xl text-black hover:text-sta-primary">
                              {linkItem.content}
                            </Popover.Button>
                          </div>
                          :
                          <span className="text-xl text-black">
                            {linkItem.content}
                          </span>
                      }
                    </div>
                  )
                })
              }
            </div>
          }
        </Popover.Panel>
      </Transition>
    </>
  )
}