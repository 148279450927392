import * as React from "react"
import {
  CountrySelector,
  usePhoneInput,
  CountryIso2,
} from "react-international-phone"
import "react-international-phone/style.css"
import "./InputPhone.css"
import { Base } from "./Base"
import {
  InputSize,
  InputSizeProps
} from "./Resource"

const themes = new Map<string, Map<string, string>>()

const defaultTheme = new Map<string, string>()
defaultTheme.set("container", "flex flex-row rounded")
defaultTheme.set("element", "w-full border rounded-tr rounded-br outline outline-1 outline-slate-100")
defaultTheme.set("selector", "bg-white border flex justify-center items-center rounded-tl rounded-bl outline outline-1 outline-slate-100")
defaultTheme.set("disabled-true", "cursor-not-allowed")
defaultTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
defaultTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

const staTheme = new Map<string, string>()
staTheme.set("container", "flex flex-row rounded")
staTheme.set("element", "w-full bg-sta-cloud border-y-1 border-r-1 md:border-y-2 md:border-r-2 border-sta-primary rounded-tr rounded-br outline outline-1 outline-slate-100")
staTheme.set("selector", "bg-sta-cloud border-1 md:border-2 border-sta-primary flex justify-center items-center rounded-tl rounded-bl outline outline-1 outline-slate-100")
staTheme.set("disabled-true", "cursor-not-allowed")
staTheme.set(`size-${InputSize.LARGE}`, "px-2 py-3")
staTheme.set(`size-${InputSize.MEDIUM}`, "p-2")

themes.set("", defaultTheme)
themes.set("sta", staTheme)

class InputPhoneStyle {

  private theme: string = ""
  private disabled: boolean = false
  private size: string = ""
  private elementClassNames: string | undefined = ""
  private appendedClassNames: string | undefined = ""

  public buildContainer(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("container")) {
      style = theme.get("container")
    }

    return style
  }

  public buildElement(): string | undefined {
    let style: string | undefined = undefined

    if (this.elementClassNames) {
      return this.elementClassNames
    }

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("element")) {
      style = theme.get("element")
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    if (this.appendedClassNames !== "") {
      style += " " + this.appendedClassNames
    }

    return style
  }

  public buildSelector(): string | undefined {
    let style: string | undefined = undefined

    const theme = themes.get(this.theme)
    if (!theme) {
      return style
    }

    if (theme.has("selector")) {
      style = theme.get("selector")
    }

    if (theme.has(`disabled-${this.disabled}`)) {
      style += " " + theme.get(`disabled-${this.disabled}`)
    }

    if (theme.has(`size-${this.size}`)) {
      style += " " + theme.get(`size-${this.size}`)
    }

    return style
  }

  public setTheme(theme: string): InputPhoneStyle {
    this.theme = theme
    return this
  }

  public setDisabled(disabled: boolean): InputPhoneStyle {
    this.disabled = disabled
    return this
  }

  public setSize(size: string): InputPhoneStyle {
    this.size = size
    return this
  }

  public setElementClassNames(elementClassNames?: string): InputPhoneStyle {
    this.elementClassNames = elementClassNames
    return this
  }

  public setAppendedClassNames(appendedClassNames?: string): InputPhoneStyle {
    this.appendedClassNames = appendedClassNames
    return this
  }
}

type InputPhoneProps = {
  id?: string
  name?: string
  placeholder?: string
  defaultValue?: string | number | ReadonlyArray<string> | undefined
  defaultCountry?: string
  value?: string | undefined
  style?: React.CSSProperties | undefined
  size?: InputSizeProps
  className?: string
  appendClassNames?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  onUpdate?: (p: UpdateMessage) => void
}

interface ParsedCountry {
  name: string;
  iso2: CountryIso2;
  dialCode: string;
  format: FormatConfig | string | undefined;
  priority: number | undefined;
  areaCodes: string[] | undefined;
}

type FormatConfig = Record<string, string> & {
  default: string;
}

type UpdateMessage = {
  phone_number: string
}

const styleBuilder = new InputPhoneStyle()

export function InputPhone(props: InputPhoneProps): React.JSX.Element {
  const {
    defaultCountry = "sg",
    disabled = false,
    size = InputSize.MEDIUM,
    className,
    appendClassNames
  } = props
 
  styleBuilder
    .setAppendedClassNames(appendClassNames)
    .setElementClassNames(className)
    .setDisabled(disabled)
    .setSize(size)
    .setTheme("sta")

  const containerClassName = styleBuilder.buildContainer()
  const elementClassNames = styleBuilder.buildElement()
  const selectorClassNames = styleBuilder.buildSelector()

  const {
    inputValue,
    handlePhoneValueChange,
    country,
    setCountry
  } = usePhoneInput({
    defaultCountry: defaultCountry,
    disableFormatting: true,
    charAfterDialCode: "",
    value: props.value,
    onChange: (data) => {
      props.onUpdate && props.onUpdate({
        phone_number: data.phone
      })
    }
  })

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    props.onInput && props.onInput(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    props.onBlur && props.onBlur(e)
  }

  const handleCountrySelect = (country: ParsedCountry) => {
    setCountry(country.iso2)

    props.onUpdate && props.onUpdate({
      phone_number: `+${country.dialCode}`
    })
  }

  return (
    <div className={containerClassName}>
      <CountrySelector
        className={selectorClassNames}
        selectedCountry={country.iso2}
        onSelect={handleCountrySelect}
        disabled={props.disabled}
        />

      <Base type="tel"
        className={elementClassNames}
        onInput={handleInput}
        onChange={handlePhoneValueChange}
        onBlur={handleBlur}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
        disabled={props.disabled}
        readOnly={props.readOnly}
        defaultValue={props.defaultValue}
        value={inputValue} />
    </div>
  )
}