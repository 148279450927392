import * as React from "react"
import { IoAtOutline, IoCallOutline, IoLocationOutline } from "react-icons/io5"
import { IoIosLink } from "react-icons/io"
import { useSearchParams, useNavigate, To } from "react-router-dom"
import { Container } from "../components/Container"
import { InputSearch } from "../components/Input"
import { Meta } from "../components/Meta"
import { Modal } from "../components/Modal"
import { Navigation, Pagination, Info } from "../components/Page"
import { Spinner } from "../components/Spinner"
import { Link } from "../components/Router"
import { NewsletterSection } from "../features/Newsletter"
import { useAutoPosition, usePage } from "../hooks"
import { dateFormat, numberFormat } from "../typeconv"
import { Member as MemberService } from "../services/api/Member"

const memberService = new MemberService({
  host: process.env.REACT_APP_API_HOST
})

type MemberItem = {
  name: string
  logoUrl?: string
  telephone: string
  email: string
  website?: string
  locationStreet: string
  joinedDate?: string
  representatives: {
    name: string
  }[]
}

const organisationDefaultLogo = "/image/placeholder-400x400.png"

export default function Member(): React.JSX.Element {
  const [page] = usePage({
    slug: "members-directory"
  })
  const [navItems] = React.useState([
    {
      to: "/membership#join",
      content: "Be a Member",
    },
    {
      to: "/membership#benefit",
      content: "Benefits of Membership",
    },
    {
      to: "/membership#stc",
      content: "Standard Trading Conditions",
    },
    {
      to: "/members-directory",
      content: "Meet The Members",
    },
    {
      to: "/membership-application",
      content: "Membership Application",
    }
  ])
  const [keyword, setKeyword] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [organisation, setOrganisation] = React.useState({
    items: [] as MemberItem[],
    totalItems: 100,
    indexes: [
      {
        value: "",
        label: "All",
        active: true,
      },
      ..."abcdefghijklmnopqrstuvwxyz".split("").map((char) => {
        return {
          value: char.toUpperCase(),
          label: char.toUpperCase(),
          active: false,
        }
      }),
    ],
    opened: false,
    detail: {} as MemberItem,
    filter: {
      query: "",
      firstChar: "",
      page: 1,
      totalItems: 10,
    }
  })
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  async function searchMember(filter?: Record<string, any>) {
    setLoading(true)
    const searchMembers = await memberService.SearchMember(filter)
    setLoading(false)
    if (searchMembers.error) {
      return
    }

    setOrganisation((prevState) => {
      return {
        ...prevState,
        items: searchMembers.data.items,
        totalItems: searchMembers.data.summary.total_items
      }
    })
  }

  function selectOrganisation(organisation: MemberItem) {
    setOrganisation((prevState) => {
      return {
        ...prevState,
        opened: true,
        detail: {
          ...organisation,
        }
      }
    })
  }

  const handleSubmitKeyword = () => {
    if (!keyword) {
      return
    }

    searchMember(organisation.filter)
  }

  React.useEffect(() => {
    if (searchParams.has("query")) {
      setOrganisation((prevState) => {
        return {
          ...prevState,
          filter: {
            ...prevState.filter,
            query: searchParams.get("query")
          }
        }
      })
    }

    if (searchParams.has("page")) {
      const page = parseInt(searchParams.get("page"))
      if (isNaN(page) || page === 0) {
        return
      }

      if (page < 1) {
        setOrganisation((prevState) => {
          return {
            ...prevState,
            filter: {
              ...prevState.filter,
              page: 1,
            }
          }
        })
        return
      }

      setOrganisation((prevState) => {
        return {
          ...prevState,
          filter: {
            ...prevState.filter,
            page
          }
        }
      })
    }

    if (searchParams.has("firstChar")) {
      const firstChar = searchParams.get("firstChar").toUpperCase()
      setOrganisation((prevState) => {
        return {
          ...prevState,
          filter: {
            ...prevState.filter,
            firstChar
          }
        }
      })

      setOrganisation((prevState) => {
        return {
          ...prevState,
          indexes: prevState.indexes.map((index) => {
            return {
              ...index,
              active: index.value === firstChar
            }
          })
        }
      })
    } else {
      setOrganisation((prevState) => {
        return {
          ...prevState,
          indexes: prevState.indexes.map((index, i: number) => {
            return {
              ...index,
              active: i === 0
            }
          })
        }
      })
    }
  }, [navigate, searchParams])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setOrganisation((prevState) => {
        return {
          ...prevState,
          filter: {
            ...prevState.filter,
            query: keyword
          }
        }
      })
    }, 1000)
    return () => clearTimeout(timer)
  }, [keyword])

  React.useEffect(() => {
    searchMember(organisation.filter)
  }, [organisation.filter])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: `${page.detail.title || "Members Directory"}`,
          description: `Singapore Transport Association maintains this list of members from time to time. If you are a member and wish to have your contact listed, please write in to admin@sta.sg.`,
        }}
      </Meta>

      <div> {/* @note: do not remove to avoid modal being shown */}
        <div className="w-full h-[250px] md:h-[400px] bg-sta-primary">
          <div className="w-full h-full flex justify-start items-center">
            <Container size="md">
              <p className="font-bold text-2xl md:text-5xl text-white my-10 xl:my-0">
                {page.detail.title || "Members Directory"}
              </p>
            </Container>
          </div>
        </div>

        <Navigation items={navItems} loading={page.loading} sticky />

        {
          page.detail.fields.boolVal("MEMBER_VISIBILITY") &&
          <>
            <div></div> {/* @note: do not remove to avoid newlseter section contain below element attributes */}
            <div className="py-10 md:py-20">
              <Container size="md">
                <div className="flex flex-col gap-4 md:gap-8 py-5 md:py-10">
                  <div className="flex flex-col gap-4">
                    <p className="w-fit font-bold text-black text-xl md:text-4xl border-b-4 border-sta-primary">
                      {page.detail.fields.textVal("MEMBER_HEADING_TEXT", `Meet the Members`)}
                    </p>
                  </div>
                  <div className="html-viewer text-lg"
                    dangerouslySetInnerHTML={{
                      __html: page.detail.fields.textVal(
                        "MEMBER_CONTENT_TEXT",
                        `<p>Singapore Transport Association maintains this list of members from time to time. If you are a member and wish to have your contact listed, please write in to <a href="mailto:admin@sta.sg">admin@sta.sg</a>.</p>`
                      )
                    }}>
                  </div>
                </div>

                <div className="py-5 md:py-10 border-t-2 border-b-2 border-sta-secondary">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 xl:col-span-4">
                      <div className="w-full xl:w-fit min-w-[300px] bg-sta-navy rounded-3xl p-8">
                        <div className="flex flex-col gap-2 md:gap-4">
                          <p className="font-bold text-sta-secondary text-base md:text-2xl">
                            Search
                          </p>
                          <InputSearch
                            size="lg"
                            name="query"
                            placeholder="Company name"
                            value={keyword}
                            onChange={(e) => { setKeyword(e.target.value) }}
                            onSubmit={handleSubmitKeyword}
                            disabled={loading}
                            actionPosition="right"
                            showAction />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 xl:col-span-8">
                      <div className="py-5 xl:py-0">
                        {
                          loading &&
                          <div className="flex justify-center items-center">
                            <Spinner size="lg" label="Loading..." labeled />
                          </div>
                        }

                        {
                          !loading && organisation.items.length === 0 &&
                          <Info
                            title="Oops member not found"
                            subtitle="Try to search with another keyword <br />or please try again later" />
                        }

                        {
                          !loading && organisation.items.length > 0 &&
                          <div className="grid grid-cols-12 auto-rows-fr gap-4 md:gap-8">
                            {
                              organisation.items.map((organisation, i: number) => {
                                return (
                                  <React.Fragment key={`organisation-item-${i}`}>
                                    <div className="col-span-12 lg:col-span-6">
                                      <div className="cursor-pointer w-full h-full bg-white rounded-xl shadow-lg border border-gray-200 p-4 md:p-5"
                                        onClick={() => { selectOrganisation(organisation) }}>
                                        <div className="w-full h-full flex flex-row justify-start items-center gap-4">
                                          <img alt="logo" className="w-[45px] md:w-[60px] object-contain aspect-square"
                                            src={organisation.logoUrl || organisationDefaultLogo} />
                                          <p className="font-bold text-lg md:text-2xl text-sta-primary">
                                            {organisation.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-6">
                  <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                    <MemberIndex
                      items={organisation.indexes}
                      to="/members-directory?firstChar=" />
                    <Pagination
                      pageRange={7}
                      itemPerPage={organisation.filter.totalItems}
                      currentPage={organisation.filter.page}
                      totalItems={organisation.totalItems}
                      to="/members-directory?page=" />
                  </div>
                </div>
              </Container>
            </div>
          </>
        }

        <NewsletterSection />
      </div>

      <Modal portalId="overlay-root"
        size="2xl"
        opened={organisation.opened}
        onClose={() => {
          setOrganisation((prevState) => {
            return {
              ...prevState,
              opened: false,
            }
          })
        }}
        lostable
        closeable>
        {{
          header: <>
            <div className="hidden md:flex flex-row gap-4 md:gap-8">
              <img alt="logo" className="aspect-square object-contain max-w-[80px] md:max-w-[120px]"
                src={organisation.detail.logoUrl || organisationDefaultLogo} />
              <div className="flex flex-col gap-2 justify-center items-start">
                <p className="font-bold text-xl md:text-2xl xl:text-4xl text-sta-primary">
                  {organisation.detail.name}
                </p>
                {
                  organisation.detail.joinedDate &&
                  <p className="text-base md:text-xl">
                    Member since {dateFormat(organisation.detail.joinedDate, 'DD MMMM YYYY')}
                  </p>
                }
              </div>
            </div>
          </>,
          content: <>
            {
              organisation.detail &&
              <div className="h-full max-h-[500px] overflow-y-auto">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-4 md:hidden">
                    <img alt="logo" className="aspect-classic object-cover object-center w-full"
                      src={organisation.detail.logoUrl || organisationDefaultLogo} />
                    <div className="flex flex-col gap-0.5 justify-center items-start">
                      <p className="font-bold text-xl md:text-4xl text-sta-primary">
                        {organisation.detail.name}
                      </p>
                      {
                        organisation.detail.joinedDate &&
                        <p className="text-base md:text-xl">
                          Member since {dateFormat(organisation.detail.joinedDate, 'DD MMMM YYYY')}
                        </p>
                      }
                    </div>
                  </div>

                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-6">
                      {
                        organisation.detail.representatives && organisation.detail.representatives.length === 0 &&
                        <div className="flex flex-col gap-2">
                          {
                            [1, 2].map((_, i: number) => {
                              return (
                                <React.Fragment key={`detail-representative-item-${i}`}>
                                  <div className="flex flex-col">
                                    <p className="font-bold text-lg md:text-xl text-sta-primary capitalize">
                                      {numberFormat(i + 1)} Representative
                                    </p>
                                    <p className="font-bold text-base md:text-lg text-black">
                                      -
                                    </p>
                                  </div>
                                </React.Fragment>
                              )
                            })
                          }
                        </div>
                      }
                      {
                        organisation.detail.representatives && organisation.detail.representatives.length > 0 &&
                        <div className="flex flex-col gap-2">
                          {
                            organisation.detail.representatives.map((representative, i: number) => {
                              return (
                                <React.Fragment key={`detail-representative-item-${i}`}>
                                  <div className="flex flex-col">
                                    <p className="font-bold text-lg md:text-xl text-sta-primary capitalize">
                                      {numberFormat(i + 1)} Representative
                                    </p>
                                    <p className="font-bold text-base md:text-lg text-black">
                                      {representative.name}
                                    </p>
                                  </div>
                                </React.Fragment>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="hidden md:flex flex-col gap-4">
                        <div className="flex flex-row gap-4">
                          <IoCallOutline className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                          {
                            organisation.detail.telephone ?
                              <Link to={`tel:${organisation.detail.telephone}`} target="_blank"
                                className="text-black hover:text-sta-primary text-base md:text-lg">
                                {organisation.detail.telephone}
                              </Link>
                              :
                              <span className="text-black text-base md:text-lg">
                                -
                              </span>
                          }
                        </div>
                        <div className="flex flex-row gap-4">
                          <IoAtOutline className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                          {
                            organisation.detail.email ?
                              <Link to={`mailto:${organisation.detail.email}`} target="_blank"
                                className="text-black hover:text-sta-primary text-base md:text-lg">
                                {organisation.detail.email}
                              </Link>
                              :
                              <span className="text-black text-base md:text-lg">
                                -
                              </span>
                          }
                        </div>
                        <div className="flex flex-row gap-4">
                          <IoLocationOutline className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                          {
                            organisation.detail.locationStreet ?
                              <Link to={`https://www.google.com/maps/search/${organisation.detail.locationStreet}`} target="_blank"
                                className="text-black hover:text-sta-primary text-base md:text-lg">
                                {organisation.detail.locationStreet}
                              </Link>
                              :
                              <span className="text-black text-base md:text-lg">
                                -
                              </span>
                          }
                        </div>
                        <div className="flex flex-row gap-4">
                          <IoIosLink className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                          {
                            organisation.detail.website ?
                              <Link to={organisation.detail.website} target="_blank"
                                className="text-black hover:text-sta-primary text-base md:text-lg">
                                {organisation.detail.website}
                              </Link>
                              :
                              <span className="text-black text-base md:text-lg">
                                -
                              </span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:hidden flex flex-col gap-4">
                    <div className="flex flex-row gap-4">
                      <IoCallOutline className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                      {
                        organisation.detail.telephone ?
                          <Link to={`tel:${organisation.detail.telephone}`} target="_blank"
                            className="text-black hover:text-sta-primary text-base md:text-lg">
                            {organisation.detail.telephone}
                          </Link>
                          :
                          <span className="text-black text-base md:text-lg">
                            -
                          </span>
                      }
                    </div>
                    <div className="flex flex-row gap-4">
                      <IoAtOutline className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                      {
                        organisation.detail.email ?
                          <Link to={`mailto:${organisation.detail.email}`} target="_blank"
                            className="text-black hover:text-sta-primary text-base md:text-lg">
                            {organisation.detail.email}
                          </Link>
                          :
                          <span className="text-black text-base md:text-lg">
                            -
                          </span>
                      }
                    </div>
                    <div className="flex flex-row gap-4">
                      <IoLocationOutline className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                      {
                        organisation.detail.locationStreet ?
                          <Link to={`https://www.google.com/maps/search/${organisation.detail.locationStreet}`} target="_blank"
                            className="text-black hover:text-sta-primary text-base md:text-lg">
                            {organisation.detail.locationStreet}
                          </Link>
                          :
                          <span className="text-black text-base md:text-lg">
                            -
                          </span>
                      }
                    </div>
                    <div className="flex flex-row gap-4">
                      <IoIosLink className="w-7 h-7 text-sta-primary" aria-hidden="true" />
                      {
                        organisation.detail.website ?
                          <Link to={organisation.detail.website} target="_blank"
                            className="text-black hover:text-sta-primary text-base md:text-lg">
                            {organisation.detail.website}
                          </Link>
                          :
                          <span className="text-black text-base md:text-lg">
                            -
                          </span>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
        }}
      </Modal>
    </>
  )
}

function generateQueryStrings(params: URLSearchParams, exceptions: string[]): string {
  const reserved = new Set<string>(exceptions)
  let url = ""
  for (const [key, value] of Array.from(params)) {
    if (reserved.has(key)) {
      continue
    }
    url += `&${key}=${value}`
  }
  return url
}

type ButtonNavigationProps = {
  children?: React.ReactNode
  to?: To
  onClick: () => void
  active?: boolean
  disabled?: boolean
  appendClassNames?: string
}

function ButtonNavigation(props: ButtonNavigationProps): React.JSX.Element {
  const {
    to,
    onClick,
    active = false,
    disabled = false,
    appendClassNames = "",
  } = props

  return (
    <>
      {
        to ?
          <>
            {
              disabled ?
                <span className={
                  "text-sm md:text-lg text-sta-gray cursor-not-allowed " +
                  appendClassNames
                }>
                  {props.children}
                </span>
                :
                <Link to={to} className={
                  "text-sm md:text-lg hover:text-sta-primary disabled:text-sta-gray disabled:cursor-not-allowed " +
                  (active ? "text-sta-primary font-semibold " : "text-black ") +
                  appendClassNames
                }>
                  {props.children}
                </Link>
            }
          </>
          :
          <button onClick={onClick}
            className={
              "text-sm md:text-lg hover:text-sta-primary disabled:text-sta-gray disabled:cursor-not-allowed " +
              (active ? "text-sta-primary font-semibold " : "text-black ") +
              appendClassNames
            }
            disabled={disabled}>
            {props.children}
          </button>
      }
    </>
  )
}

type MemberIndexProps = {
  items?: {
    value: string
    label: string
    active: boolean
  }[]
  to?: To
  onSelected?: ({ value, label }: { value: string, label: string }) => void
}

function MemberIndex(props: MemberIndexProps): React.JSX.Element {
  const {
    items = [],
    to,
    onSelected = () => { },
  } = props

  function selectIndex({ value, label }: { value: string, label: string }) {
    onSelected && onSelected({
      value,
      label,
    })
  }

  const [searchParams] = useSearchParams()
  const params = generateQueryStrings(searchParams, ["firstChar"])

  return (
    <div className="flex flex-row flex-wrap justify-center items-center gap-3">
      {
        items.map((item, i: number) => {
          return (
            <React.Fragment key={`index-item-${i}`}>
              <ButtonNavigation to={to + `${item.value}` + params}
                active={item.active}
                onClick={() => {
                  selectIndex({
                    value: item.value,
                    label: item.label,
                  })
                }}>
                {item.label}
              </ButtonNavigation>
            </React.Fragment>
          )
        })
      }
    </div>
  )
}
